import { DonationLevelContext } from "../../context/DonationLevelContext/DonationLevelContext"
import {PropsWithChildren, useState, useEffect, useCallback, useRef} from 'react'
import {useSearchParams} from 'react-router-dom'

const LEVEL_LOCAL_STORAGE_KEY = 'donationtracker.donationLevel'
const LEVEL_SEARCH_PARAMS_KEX = 'level'

export const DonationLevelProvider = (props: PropsWithChildren) => {
    const [level, setLevel] = useState(parseInt(localStorage.getItem(LEVEL_LOCAL_STORAGE_KEY) ?? '0'))
    const intervalId = useRef(0)

    const fetchLevel = useCallback(() => {
        fetch("/api/level").then(async res => {
            if (res.ok) {
                try {
                    const body = await res.json()
                    if (!isNaN(body.level)) {
                        const level = body.level * 100
                        setLevel(Math.min(100, level))
                        localStorage.setItem(LEVEL_LOCAL_STORAGE_KEY, level.toString())
                    }        
                } catch (e) {
                    console.log("failed to read level")
                }
            }
        })
    }, [setLevel])

  const [searchParams] = useSearchParams()
  useEffect(() => {
    window.clearInterval(intervalId.current)

    const searchParamsLevel = searchParams.get(LEVEL_SEARCH_PARAMS_KEX)
    if (searchParamsLevel) {
        const searchParamsLevelInt = parseInt(searchParamsLevel)
        if (searchParamsLevelInt >= 0 && searchParamsLevelInt <= 100) {
            setLevel(searchParamsLevelInt)
            localStorage.setItem(LEVEL_LOCAL_STORAGE_KEY, searchParamsLevelInt.toString())
        }
    } else {
        fetchLevel()
        intervalId.current = window.setInterval(fetchLevel, 5000)
    }
  }, [searchParams, setLevel, fetchLevel])

    return <DonationLevelContext.Provider value={level}>
        {props.children}
    </DonationLevelContext.Provider>
}