import 'bootstrap/dist/css/bootstrap.min.css';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import {Route, Routes} from 'react-router-dom'
import { Login } from './admin/Login/Login';
import {useState, useEffect} from 'react'
import { clearToken, getToken } from './utils';
import {useNavigate} from 'react-router-dom'

export const Admin = () => {
    const [isLoggedIn, setIsLoggedIn] = useState(false)

    useEffect(() => {
        if (!getToken()) {
            setIsLoggedIn(false)
            return
        }
        setIsLoggedIn(true)
    }, [])

    const navigate = useNavigate()

    const logout = () => {
        clearToken()
        setIsLoggedIn(false)
        navigate("/admin/login")
    }   
    return <>
        <Navbar expand="lg" className="bg-body-tertiary">
        <Container>
            <Navbar.Brand href="#home">S2 Spendentracker Admin</Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto">
                <Nav.Link href="/admin">Home</Nav.Link>
                {!isLoggedIn && 
                <Nav.Link onClick={() => {
                    navigate("/admin/login")
                }}>Login</Nav.Link>
                }
                {isLoggedIn && 
                <Nav.Link onClick={logout}>Logout</Nav.Link>
                }
            </Nav>
            </Navbar.Collapse>
        </Container>
        </Navbar>
        <Container>
            <Routes>
            <Route path='/*' element={<Content isLoggedIn={isLoggedIn}/>}/>
            <Route path='/login' element={<Login onLogin={() => {
                navigate("admin")
                setIsLoggedIn(true)
            }} />}/>
            </Routes>
        </Container>
    </>
}


type ContentProps = {
    isLoggedIn: boolean
}
const Content = (props: ContentProps) => {
    const [isLoading, setIsLoading] = useState(true)
    const [isError, setIsError] = useState(false)
    const [actualInput, setActualInput] = useState<string|undefined>(undefined)
    const [goalInput, setGoalInput] = useState<string|undefined>(undefined)
    const [inputDisabled, setInputDisabled] = useState(false)
    const [currentSpendenstand, setCurrentSpendenstand] = useState({actual: 0, goal: 0})

    useEffect(() => {
        if (!isLoading) {
            return
        }
        fetch("/api/spendenstand", {
            headers: {
                Authorization: `Bearer ${getToken()}`
            }
        }).then(async res => {
            if (res.ok) {
                try {

                    const body = await res.text()
                    console.log(body)
                    const spendenstand = await JSON.parse(body)
                    console.log(spendenstand)
                    setCurrentSpendenstand(spendenstand)
                    setActualInput(undefined)
                    setGoalInput(undefined) 
                    setIsError(false)
                    setIsLoading(false)
                    return
                } catch (e) {
                    console.log(e)
                    setIsError(true)
                    setIsLoading(false)
                }
            }
            setIsError(true)
            setIsLoading(false)
        })
    }, [isLoading, setIsLoading, setIsError, setActualInput, setGoalInput])

    if (isLoading) {
        return <Container>
            Loading
        </Container>
    }

    if (!props.isLoggedIn || isError) {
        return <Container>
            Nicht eingeloggt.
        </Container>
    }

    const onSubmit = async () => {
        const newActual = actualInput !== undefined ? parseInt(actualInput) : currentSpendenstand.actual
        const newGoal = goalInput !== undefined ? parseInt(goalInput) : currentSpendenstand.goal

        setInputDisabled(true)
        await fetch("/api/spendenstand", {
            method: "POST",
            headers: {
                Authorization: `Bearer ${getToken()}`
            },
            body: JSON.stringify({
                "actual": newActual,
                "goal": newGoal,
            })
        })
        setIsLoading(true)
        setInputDisabled(false)
    }

    return <>
       <Form onSubmit={e => {
            e.preventDefault()
            onSubmit()
        }}>
        <Form.Group className="mb-3" controlId="formBasicUsername">
            <Form.Label>Aktueller Spendenstand</Form.Label>
            <Form.Control type="" disabled={inputDisabled} placeholder={currentSpendenstand.actual.toString()} value={actualInput} onChange={e => setActualInput(e.target.value)}/>
        </Form.Group>

        <Form.Group className="mb-3" controlId="formBasicPassword">
            <Form.Label>Spendenziel</Form.Label>
            <Form.Control type="" disabled={inputDisabled} placeholder={currentSpendenstand.goal.toString()} value={goalInput} onChange={e => setGoalInput(e.target.value)}/>
        </Form.Group>
        <Button variant="primary" type="submit" disabled={inputDisabled} >
            Speichern
        </Button>
        </Form>
    </>
}