const LOCALSTORAGE_TOKEN_KEY = "s2.token"

export const getToken = () => {
    return localStorage.getItem(LOCALSTORAGE_TOKEN_KEY)
}

export const setToken = (token: string) => {
    localStorage.setItem(LOCALSTORAGE_TOKEN_KEY, token)
}

export const clearToken = () => {
    localStorage.removeItem(LOCALSTORAGE_TOKEN_KEY)
}