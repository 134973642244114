import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import {useState, useCallback} from 'react'
import { setToken } from '../../utils';

export type LoginProps = {
    onLogin?: () => void
}

export const Login = (props: LoginProps) => {
    const [username, setUsername] = useState("")
    const [password, setPassword] = useState("")

    const onSubmit = useCallback(async () => {
        const requestBody = {
            username,
            password,
        }
        try {
            const result = await fetch("/api/login", {
                body: JSON.stringify(requestBody),
                method: "POST"
            })   
            if (result.ok) {
                const body = await result.json()
                if (body.token) {
                    setToken(body.token)
                    props.onLogin?.()
                }
            }

        } catch (e) {
            console.error(e)
        }
    }, [username, password, props])

    return <>
        <Form onSubmit={e => {
            e.preventDefault()
            onSubmit()
        }}>
        <Form.Group className="mb-3" controlId="formBasicUsername">
            <Form.Label>Username</Form.Label>
            <Form.Control type="" placeholder="Username" value={username} onChange={e => setUsername(e.target.value)}/>
        </Form.Group>

        <Form.Group className="mb-3" controlId="formBasicPassword">
            <Form.Label>Password</Form.Label>
            <Form.Control type="password" placeholder="Password" value={password} onChange={e => setPassword(e.target.value)}/>
        </Form.Group>
        <Button variant="primary" type="submit" >
            Submit
        </Button>
        </Form>
    </>
}