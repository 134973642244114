import styles from './PhotoBarometer.module.css'

export type PhotoBarometerProps = {
    direction: number
    min: number
    max: number
    level: number
    basePhoto: string
    cutoutPhoto: string
    width: number
    height: number
    show: boolean
}

export const PhotoBarometer = (props: PhotoBarometerProps ): React.JSX.Element => {
    let {min, max, level, width, height} = props

    level = level < 0 ? 0: level > 100 ? 100 : level

    const step = (max - min) / 100

    const mid = min + level * step
    const start = mid - 5 * step
    const end = mid + 5 * step

    const sizeCss = {
        width: `min(100cqw, ${width}*100cqh/${height})`,
        height: `min(100cqh, ${height}*100cqw/${width})`,
    }

    const showClassName = props.show ? styles.show : styles.hide
    const maskImageCss = `linear-gradient(${props.direction}deg, rgb(0, 0, 0, 0) ${start}%, rgba(0, 0, 0, 0.5) ${mid}%, rgba(0, 0, 0, 1) ${end}%)`
    return <div className={`${styles.container} ${showClassName}`}>
        <img src={props.basePhoto} className={styles.base} style={sizeCss} alt=''/>
        <div className={`${styles.midlayer} ${showClassName}`} style={{
            ...sizeCss,
            maskImage: maskImageCss,
            WebkitMaskImage: maskImageCss,
            }}/>
        <img src={props.cutoutPhoto} className={`${styles.cutout} ${showClassName}`} style={sizeCss} alt=''/>
    </div>
}


