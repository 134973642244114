import { PhotoBarometer } from "../PhotoBarometer/PhotoBarometer"
import Base1 from './spinnakerphotos/1/base.png'
import Cutout1 from './spinnakerphotos/1/cutout.png'
import Base2 from './spinnakerphotos/2/base.png'
import Cutout2 from './spinnakerphotos/2/cutout.png'
import Base3 from './spinnakerphotos/3/base.png'
import Cutout3 from './spinnakerphotos/3/cutout.png'
import Base4 from './spinnakerphotos/4/base.png'
import Cutout4 from './spinnakerphotos/4/cutout.png'
import Base5 from './spinnakerphotos/5/base.png'
import Cutout5 from './spinnakerphotos/5/cutout.png'
import Base6 from './spinnakerphotos/6/base.png'
import Cutout6 from './spinnakerphotos/6/cutout.png'
import styles from './BarometerSlideshow.module.css'
import { useEffect, useState, useRef, useContext } from 'react'
import { DonationLevelContext } from "../../context/DonationLevelContext/DonationLevelContext"

type BarometerSlideshowProps = {
    slideTimeout: number
}



export const BarometerSlideshow = (props: BarometerSlideshowProps): React.JSX.Element => {
    const donationLevel = useContext(DonationLevelContext)

    const barometerIndex = useRef(0)
    const intervalId = useRef(0)
    const [currentIndex, setCurrentIndex] = useState(barometerIndex.current)

    const barometers = [
        <PhotoBarometer basePhoto={Base1} cutoutPhoto={Cutout1} direction={0} level={donationLevel} min={29} max={100} width={645} height={430} show={currentIndex === 0}/>,
        <PhotoBarometer basePhoto={Base2} cutoutPhoto={Cutout2} direction={90} level={donationLevel} min={0} max={100} width={3456} height={5184} show={currentIndex === 1}/>,
        <PhotoBarometer basePhoto={Base3} cutoutPhoto={Cutout3} direction={0} level={donationLevel} min={36} max={83} width={1920} height={1080} show={currentIndex === 2}/>,
        <PhotoBarometer basePhoto={Base4} cutoutPhoto={Cutout4} direction={0} level={donationLevel} min={20.5} max={100} width={2000} height={1180} show={currentIndex === 3}/>,
        <PhotoBarometer basePhoto={Base5} cutoutPhoto={Cutout5} direction={0} level={donationLevel} min={15} max={100} width={1024} height={768} show={currentIndex === 4}/>,
        <PhotoBarometer basePhoto={Base6} cutoutPhoto={Cutout6} direction={90} level={donationLevel} min={0} max={100} width={2000} height={1500} show={currentIndex === 5}/>,
    ]

    useEffect(() => {
        window.clearInterval(intervalId.current)
        intervalId.current = window.setInterval(() => {
            barometerIndex.current = (barometerIndex.current + 1) % barometers.length
            setCurrentIndex(barometerIndex.current)
        }, props.slideTimeout)
        return () => {
            window.clearInterval(intervalId.current)
        }
    }, [barometerIndex, intervalId, barometers.length, props.slideTimeout, setCurrentIndex])

   

    return <div className={styles.container}>
        {barometers.map((element, index) => {
            return (<div key={index} className={styles.barometer}>{element}</div>)
        })}
        </div>
}