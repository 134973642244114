import './App.css';
import React from 'react';
import { BarometerSlideshow } from './components/BarometerSlideshow/BarometerSlideshow';
import { DonationLevelProvider } from './components/DonationLevelProvider/DonationLevelProvider';
import { useSearchParams } from 'react-router-dom';

const SLIDE_TIMEOUT_SEARCH_PARAMS_KEY = 'timeout'

function App() {
  const [searchParams] = useSearchParams()
  let slideTimeout = parseInt(searchParams.get(SLIDE_TIMEOUT_SEARCH_PARAMS_KEY) ?? '0')
  if (slideTimeout === 0) {
    slideTimeout = 60000
  }
  console.log(`Slide timeout: ${slideTimeout}`)

  return (
    <div className="App" style={{
      width: "100vw",
      height: "100vh",
      backgroundColor: "black",
    }}>
      <DonationLevelProvider>
        <BarometerSlideshow slideTimeout={slideTimeout}></BarometerSlideshow>
      </DonationLevelProvider>
    </div>
  );
}

export default App;
